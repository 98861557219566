import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router";
import store from "./store";
import './plugins/vee-validate'
import {setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate';
import AlertDialog from '@/components/_Default/AlertDialog'
import ConfirmDialog from '@/components/_Default/ConfirmDialog'
import UnderMaintenanceDialog from '@/components/_Default/underMaintenanceDialog'
import SnackBar from '@/components/_Default/SnackBar'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import global from './plugins/global'
import './plugins/filters'
import "./plugins/vuetify-money.js";
import vueMoment from 'vue-moment';



// import { RecycleScroller,DynamicScroller,DynamicScrollerItem } from 'vue-virtual-scroller'

// Vue.component('RecycleScroller', RecycleScroller)
// Vue.component('DynamicScroller', DynamicScroller)
// Vue.component('DynamicScrollerItem', DynamicScrollerItem)
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'


// Vue.component('perfect-scrollbar', PerfectScrollbar);
Vue.component('alert-dialog', AlertDialog);
Vue.component('confirm-dialog', ConfirmDialog);
Vue.component('under-maintenance-dialog', UnderMaintenanceDialog);

Vue.component('snack-bar', SnackBar);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
setInteractionMode('aggressive');
Vue.config.productionTip = false;
Vue.use(PerfectScrollbar);
Vue.use(global);
Vue.use(vueMoment);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
