<template>
  <v-dialog
      :value="dialog"
      @input="toggleDialog"
      max-width="500px"
      :fullscreen="!$vuetify.breakpoint.lgAndUp"
  >
    <div
        class=" fill-height w-100"
        ref="containerWrap"
    >

      <v-card
          max-width="600"
          max-height="1000"
          class="mx-auto"
      >
        <v-toolbar
            dark
            color="#080F1C"
            class="d-grid"
        >
          <v-toolbar-title
              class="d-flex align-center justify-center w-100"
          >회원가입
          </v-toolbar-title>
          <v-btn
              icon
              class="closeBtn"
              color="#ffffff"
              @click="toggleDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text :class="$vuetify.breakpoint.lgAndUp ? 'pa-9': 'px-0'"
                     class="joinWrap"
        >
          <validation-observer
              ref="boardWriteForm"
              v-slot="{ invalid }"
          >
            <v-form
                class=""
                @submit.prevent="registData"
            >
              <v-row class="ma-0">
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="userId"
                      rules="required"
                  >
                    <h3 class="font-weight-bold pb-1">아이디</h3>
                    <v-text-field
                        v-model="userId"
                        label=""
                        placeholder="영문, 숫자 4~16자"
                        solo
                        outlined
                        required
                        :rules="[rules.nullCheck,rules.notAllowSpace,rules.disableSpecialText, rules.between4and16InputRange,rules.inputEngAndNum,rulesCheckOverlapId]"
                        :success="userIdSuccess"
                        :success-messages="availableUseridMsg"
                        name="userId"
                        @input="changeInput('userId')"
                        class="rounded-0 searchInput"
                        background-color="#252F41"

                    >
                      <template
                          v-slot:append>
                        <v-btn
                            text
                            color="white"
                            :loading="overlapIdBtn"
                            class="rounded-0 mr-n3 px-0 checkBtn"
                            :disabled="!activeCheckUserIdBtn"
                            @click="isAvailableCheck('userId')"
                        >
                          중복확인
                        </v-btn>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="userPassword"
                      rules="required"
                  >
                    <h3 class="font-weight-bold pb-1">비밀번호</h3>
                    <v-text-field
                        v-model="userPassword"
                        label=""
                        placeholder="영문, 숫자 6~16자"
                        solo
                        outlined
                        required
                        type="password"
                        name="userPassword"
                        class="rounded-0 searchInput"
                        background-color="#252F41"
                        :rules="[rules.nullCheck, rules.between6and16InputRange]"
                        :error-messages="errors"
                        :success="valid"
                        :hide-details="(valid || userPassword.length === 0)"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="userPasswordCheck"
                      rules="required|confirmed:userPassword|min:1"
                  >
                    <v-text-field
                        v-model="userPasswordCheck"
                        label=""
                        placeholder="비밀번호 재입력"
                        solo
                        outlined
                        required
                        type="password"
                        name="userPasswordCheck"
                        class="rounded-0 searchInput"
                        background-color="#252F41"
                        :error-messages="errors"
                        :success="(pwSuccess = valid)"
                        :success-messages="matchingPW"
                        :rules="[rules.nullCheck]"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="nickName"
                      rules="required"
                  >
                    <h3 class="font-weight-bold pb-1">닉네임</h3>
                    <v-text-field
                        v-model="nickName"
                        label=""
                        placeholder="2~8자"
                        solo
                        outlined
                        required
                        :rules="[rules.nullCheck,rules.notAllowSpace,rules.disableSpecialText,rules.between2and8InputRange,rulesCheckOverlapNickname]"
                        :success="nickNameSuccess"
                        :success-messages="availableNickNameMsg"
                        name="nickName"
                        @input="changeInput('nickName')"
                        class="rounded-0 searchInput"
                        background-color="#252F41"

                    >
                      <template
                          v-slot:append>
                        <v-btn
                            text
                            color="white"
                            :loading="overlapNicknameBtn"
                            class="rounded-0 mr-n3 px-0 checkBtn"
                            :disabled="!activeCheckNickNameBtn"
                            @click="isAvailableCheck('nickName')"
                        >
                          중복확인
                        </v-btn>
                      </template>
                    </v-text-field>
                  </validation-provider>

                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <h3 class="font-weight-bold pb-1">은행</h3>

                  <v-row
                      no-gutters
                      class="mb-1"
                  >
                    <v-col
                        col="7"
                        class="mr-2"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="select"
                          rules="required"
                      >
                        <v-select
                            v-model="bankSelect"
                            :items="bankList"
                            item-text="text"
                            item-value="value"
                            :error-messages="errors"
                            label=""
                            placeholder="은행을 선택해주세요"
                            data-vv-name="select"
                            solo
                            outlined
                            flat
                            color="success"
                            class="rounded-0 searchSelect"
                            background-color="#252F41"
                            hide-details
                            required
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col
                        col="5"
                    >
                      <validation-provider
                          v-slot="{ errors, valid }"
                          name="bankAccountHolder"
                          rules="required"
                      >
                        <v-text-field
                            v-model="bankAccountHolder"
                            label=""
                            placeholder="예금주"
                            solo
                            outlined
                            required
                            color="success"
                            :error-messages="errors"
                            name="bankAccountHolder"
                            class="rounded-0 searchInput"
                            background-color="#252F41"
                            hide-details
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="bankAccount"
                      rules="required"
                  >
                    <v-text-field
                        v-model="bankAccount"
                        label=""
                        placeholder="계좌번호"
                        solo
                        outlined
                        required
                        :error-messages="errors"
                        name="bankAccount"
                        class="rounded-0 searchInput"
                        background-color="#252F41"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <h3 class="font-weight-bold pb-1">환전 비밀번호</h3>
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="exchangePassword"
                      rules="required"
                  >

                    <v-text-field
                        v-model="exchangePassword"
                        label=""
                        placeholder="영문, 숫자 6~16 자"
                        solo
                        outlined
                        required
                        type="password"
                        name="exchangePassword"
                        class="rounded-0 searchInput"
                        background-color="#252F41"
                        :rules="[rules.nullCheck, rules.between6and16InputRange]"
                        :error-messages="errors"
                        :success="valid"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <h3 class="font-weight-bold pb-1">휴대폰 번호</h3>
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="phoneNumber"
                      rules="required|phone|max:13|min:12"
                  >

                    <v-text-field
                        v-model="phoneNumber"
                        label=""
                        placeholder="숫자만 입력하세요"
                        solo
                        outlined
                        required
                        name="phoneNumber"
                        class="rounded-0 searchInput"
                        background-color="#252F41"
                        v-mask="phoneMask"
                        :rules="[rules.nullCheck]"
                        :error-messages="errors"
                        :success="valid"

                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-0 mb-2"
                >
                  <h3 class="font-weight-bold pb-1">추천 코드</h3>
                  <validation-provider
                      v-slot="{ errors, valid }"
                      name="recommenderCode"
                      rules="required"
                  >
                    <v-text-field
                        v-model="recommenderCode"
                        placeholder="추천코드를 입력해주세요."
                        label=""
                        solo
                        outlined
                        required
                        :error-messages="errors"
                        name="recommenderCode"
                        class="rounded-0 searchInput"
                        background-color="#252F41"
                        :readonly="host === 'marina-1.com'"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>

              </v-row>
              <div class="mb-4">
                <v-btn
                    tile
                    color="#289CAF"
                    class=""
                    block
                    x-large
                    :loading="joinLoading"
                    type="submit"
                    :disabled="(invalid || !userIdSuccess || !nickNameSuccess)"
                >
                  회원가입
                </v-btn>
              </div>

            </v-form>


          </validation-observer>
        </v-card-text>
      </v-card>
    </div>

    <under-maintenance-dialog
        :dialog.sync=dialog.underMaintenance
        :text=dialog.underMaintenanceText
    ></under-maintenance-dialog>
    <v-overlay
        absolute
        z-index="999"
        :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>

</template>


<script>
import {mask} from 'vue-the-mask'
// import axios from "axios";
import {eventBus} from "@/plugins/eventBus";

export default {
  name: "MemberJoin",
  data: () => ({
    overlapIdBtn: false,
    overlapNicknameBtn: false,
    joinLoading: false,
    loading: false,
    userId: '',
    nickName: '',
    userPassword: '',
    userPasswordCheck: '',
    bankSelect: '',
    bankList: [],
    bankAccountHolder: '',
    bankAccount: '',
    exchangePassword: '',
    phoneNumber: '',
    recommenderCode: '',
    host:'',

    dialog: {
      alert: false,
      alertText: '',
      alertActionType: '',
      alertOption: {
        persistent: false
      },
      underMaintenance: false,
      underMaintenanceText: '',
      snackBar: false,
      snackBarText: '',
    },
    availableUserId: false,
    userIdSuccess: false,
    availableNickName: false,
    nickNameSuccess: false,
    passwordShow: false,
    pwSuccess: false,
    rules: {
      nullCheck: v => !!v || '값을 입력해주세요',
      inputEngAndNum: v => /^[a-zA-Z0-9]*$/.test(v) || '아이디는 영문+숫자만 입력 가능합니다.',
      between4and16InputRange: v => (v.length >= 4 && v.length <= 16) || '4 ~ 16 자리를 입력해 주세요.',
      between6and16InputRange: v => (v.length >= 6 && v.length <= 16) || '6 ~ 16 자리를 입력해 주세요.',
      between2and8InputRange: v => (v.length >= 2 && v.length <= 8) || '2 ~ 8 자리를 입력해 주세요.',
      notAllowSpace: v => !v.match(/\s/g) || '공백을 제거해주세요.',
      disableSpecialText : v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '특수문자를 사용할 수 없습니다.'

    },
    phoneMask: '###-####-####',
  }),
  directives: {
    mask,
  },
  components: {},
  props: {
    code: {
      type: String,
      default: "",
    },
    joinDialog: {
      type: Boolean
    },

  },
  methods: {
    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:joinDialog', !this.joinDialog);
      // this.$emit('input');
    },
    showAlert: function () {
      console.log(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    getBankData: async function () {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .get(`/app/common/banks`,
              {
                params: {},
              })
          .then(response => {
            console.log("bank", response)
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
              for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].enabled) {
                  this.bankList.push({text: response.data[i].display, value: response.data[i].display})
                  // this.$set(this.cart, this.cart.findIndex(v => v.item.sportsGameId === item.sportsGameId), {item:item,target:target});
                }

              }

            } else {
              isSuccess.flag = false;
              isSuccess.msg = "은행 목록 불러오기 오류.";
            }
            isSuccess.code = response.status;
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getBankData()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                  this.dialog.alertText = refreshTokenResult.msg
                } else {
                  this.dialog.alertText = refreshTokenResult.msg
                }
                this.dialog.alert = true;


              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })


      if (isSuccess.flag) {

      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else {
          this.dialog.alertText = '은행 목록 불러오기 오류.';
          this.dialog.alert = true;
        }
      }
      this.loading = false;
    },
    registData: async function () {
      this.loading = true;
      this.joinLoading = true;
      const recommenderResult = await this.checkRecommender()
      console.log(recommenderResult)
      if (recommenderResult.flag) {
        let isSuccess = {flag: false, code: 400, msg: ''};
        let data = {
          memberInfo: {
            username: this.userId,
            password: this.userPassword,
            nickname: this.nickName,
            bank: this.bankSelect,
            bankAccount: this.bankAccount,
            bankAccountHolder: this.bankAccountHolder,
            exchangePassword: this.exchangePassword,
            phone: this.phoneNumber.replace(/-/g, ''),
            recommenderCode: this.recommenderCode,
          }


        }
        await axios
            .post(`/agency/join`,
                data)
            .then(response => {
              console.log(response)
              if (response.status === 200) {
                // 사용가능
                isSuccess.flag = true;
                isSuccess.msg = response.data.message;
              } else {
                isSuccess.flag = false;
                isSuccess.msg = "회원가입을 실패하였습니다.";
              }
              isSuccess.code = response.status;
            })
            .catch(ex => {
              console.warn("ERROR!!!!! : ", ex);
              isSuccess.flag = false;
              isSuccess.code = ex.response.status;
              isSuccess.msg = ex.response.data.message;

            });
        if (isSuccess.flag) {

          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
          this.dialog.alertActionType = 'joinComplete'
          // this.toggleDialog()
        } else {
          this.dialog.snackBar = false;
          this.dialog.snackBarText = '';
          if (isSuccess.code === 400) {
            this.dialog.alertText = isSuccess.msg;
            this.dialog.alert = true;
          } else {
            this.dialog.alertText = '회원가입을 실패하였습니다.';
            this.dialog.alert = true;
          }
        }
      } else {
        this.dialog.alertText = recommenderResult.msg;
        this.dialog.alert = true;
      }
      this.joinLoading = false;
      this.loading = false;
    },
    changeInput: function (type) {
      if (type === 'userId') {
        if (this.availableUserId) {
          this.availableUserId = false;
          this.userIdSuccess = false;
        }
      } else {
        if (this.availableNickName) {
          this.availableNickName = false;
          this.nickNameSuccess = false;
        }
      }

    },
    isAvailableCheck: async function (type) {
      if ((type === 'userId' &&
              this.userId.length > 0 &&
              /^[a-zA-Z0-9]*$/.test(this.userId) &&
              (this.userId.length >= 4 && this.userId.length <= 16)) ||
          (type === 'nickName' &&
              this.nickName.length > 0 &&
              (this.nickName.length >= 2 && this.nickName.length <= 8)
          )) {
        this.loading = true;
        let data = {}
        if (type === 'userId') {
          this.overlapIdBtn = true
          data = {
            username: this.userId
          }
        } else {
          this.overlapNicknameBtn = true
          data = {
            nickname: this.nickName
          }
        }
        let isSuccess = {flag: false, code: 400, msg: ''};
        await axios
            .post(`/app/member/check/${type === 'userId' ? 'username' : 'nickname'}`,
                data)
            .then(response => {
              console.log(response)
              if (response.status === 200) {
                // TODO 다이얼로그로 변경
                // 사용가능

                isSuccess.flag = true;
                isSuccess.msg = response.data.message;
              } else {
                // TODO 다이얼로그로 변경
                isSuccess.flag = false;
                isSuccess.msg = "중복 체크 오류.";
              }
            })
            .catch(ex => {
              // TODO 다이얼로그로 변경
              isSuccess.flag = false;
              isSuccess.code = ex.response.status;
              isSuccess.msg = ex.response.data.message;
              console.warn("ERROR!!!!! : ", ex);
            });

        if (isSuccess.flag) {
          this.dialog.alertText = '사용 가능합니다.';
          this.dialog.alert = true;
          if (type === 'userId') {
            this.availableUserId = true;
            this.userIdSuccess = true;
          } else {
            this.availableNickName = true;
            this.nickNameSuccess = true;
          }
        } else {
          if (isSuccess.code === 400) {
            this.dialog.alertText = isSuccess.msg;
            this.dialog.alert = true;
          } else if (isSuccess.code === 401) {
            let refreshTokenResult = await this.$refreshToken()
            if (refreshTokenResult.flag) {
              await this.isAvailableCheck(type)
            } else {
              if (refreshTokenResult.status === 403) {
                this.dialog.alertActionType = 'logout'
                this.dialog.alertOption.persistent = true
                this.dialog.alertText = refreshTokenResult.msg
              } else {
                this.dialog.alertText = refreshTokenResult.msg
              }
              this.dialog.alert = true;

            }
          } else if (isSuccess.code === 403) {
            this.dialog.alert = true;
            this.dialog.alertText = isSuccess.msg;
            this.dialog.alertActionType = 'logout'
            this.dialog.alertOption.persistent = true
          } else {
            this.dialog.alertText = '중복 체크 오류.';
            this.dialog.alert = true;
          }
        }
        if (type === 'userId') {
          this.overlapIdBtn = false

        } else {
          this.overlapNicknameBtn = false
        }
        this.loading = false;
      } else {
        if (type === 'userId') {
          if (!this.userId.length > 0) {
            this.dialog.alertText = '값을 입력해주세요.';
          } else if (!/^[a-zA-Z0-9]*$/.test(this.userId)) {

            this.dialog.alertText = '아이디는 영문+숫자만 입력 가능합니다.';
          } else if (!(this.userId.length >= 4 && this.userId.length <= 16)) {
            this.dialog.alertText = '아이디를 4 ~ 16 자리를 입력해 주세요.';
          } else {
            this.dialog.alertText = '관리자에 문의해주세요.';

          }
        } else {
          if (!this.nickName.length > 0) {
            this.dialog.alertText = '값을 입력해주세요.';
          } else if (!(this.nickName.length >= 4 && this.nickName.length <= 16)) {
            this.dialog.alertText = '닉네임을를 2 ~ 8 자리를 입력해 주세요.';
          } else {
            this.dialog.alertText = '관리자에 문의해주세요.';
          }

        }
        this.dialog.alert = true;
        return false;
      }
    },
    getData: function () {
      this.getBankData();
    },
    onResize: function () {
      let betListHeight = this.$refs.containerWrap.clientHeight;
      this.betListHeight = betListHeight

    },
    checkRecommender: async function () {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};

      await axios
          .post('/app/member/check/recommender',
              {code: this.recommenderCode},)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "잘못된 추천인 코드입니다.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })


      if (isSuccess.flag) {

      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.checkRecommender(this.recommenderCode)
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
              this.dialog.alertText = refreshTokenResult.msg
            } else {
              this.dialog.alertText = refreshTokenResult.msg
            }
            this.dialog.alert = true;

          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '잘못된 추천인 코드입니다.';
          this.dialog.alert = true;
        }
      }
      return isSuccess
    },
    checkSiteConfig: async function () {
      let result = await this.$updateSiteInfo()
      console.log(result)
      if (result.underMaintenance) {
        this.dialog.underMaintenance = true
        console.log(result.constructionText)
        if (result.constructionText.length > 0) {
          this.dialog.underMaintenanceText = result.constructionText
        } else {
          this.dialog.underMaintenanceText = '사이트 점검 중이므로 이용하실 수 없습니다.';
        }
      }
    }
  },
  computed: {
    activeCheckUserIdBtn: function () {
      return this.userId.length > 0 && !this.userId.match(/\s/g) && !/[~!@#$%^&*()_+|<>?:{}]/.test(this.userId) && /^[a-zA-Z0-9]*$/.test(this.userId) && (this.userId.length >= 4 && this.userId.length <= 16);
    },
    activeCheckNickNameBtn: function () {
      return this.nickName.length > 0 && !this.nickName.match(/\s/g) && !/[~!@#$%^&*()_+|<>?:{}]/.test(this.nickName) && (this.nickName.length >= 2 && this.nickName.length <= 8);
    },
    matchingPW: function () {
      return this.pwSuccess ? ['비밀번호가 확인되었습니다.'] : []
    },
    rulesCheckOverlapId: function () {
      if (!this.availableUserId) {
        return '중복 확인이 필요합니다.';
      } else {
        return true;
      }
    },
    rulesCheckOverlapNickname: function () {
      if (!this.availableNickName) {
        return '중복 확인이 필요합니다.';
      } else {
        return true;
      }
    },
    availableUseridMsg: function () {
      return this.userIdSuccess ? ['사용 가능한 아이디입니다.'] : []
    },
    availableNickNameMsg: function () {
      return this.nickNameSuccess ? ['사용 가능한 닉네임입니다.'] : []
    },
    scrollerStyle() {
      return {
        // height: `${this.cartHeight}px`,
        // height: `${this.betListHeight} px`,
        height: `calc(100% )`,
      }

    },
  },
  mounted() {
    console.log(this.$route.path)
    this.checkSiteConfig();
    this.getData();
    this.onResize()
    if (this.$route.query.code !== '' && this.$route.query.code) {
      this.recommenderCode = this.$route.query.code
    }

    this.host = window.location.host

    switch (this.host.replace('www.','')) {
      case 'marina-1.com':
        this.recommenderCode = "baby"
        break;

      default:
        break;
    }

  },

  created() {
    this.$eventBusInitAlert()
  },
  watch: {
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {
          this.showAlert()
        }
      },
      deep: true

    },
    code: {
      handler: function (val, oldVal) {
        console.log('joinData',this.joinData)
      },
      deep: true

    }
  }
}
</script>

<style scoped>
.mainLogo {
  padding: 80px 0;
}

.checkBtn {
  color: #ffffff;
  font-size: 12px !important;
  line-height: 1.5 !important;
  padding-right: 0 !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

div ::v-deep .v-text-field--outlined.searchInput > .v-input__control > .v-input__slot {
  border: 1px solid rgb(59, 74, 101) !important;
  border-radius: 0.3rem !important;
}
</style>