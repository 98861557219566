import { render, staticRenderFns } from "./LoginDialog.vue?vue&type=template&id=1b79314e&scoped=true&"
import script from "./LoginDialog.vue?vue&type=script&lang=js&"
export * from "./LoginDialog.vue?vue&type=script&lang=js&"
import style0 from "./LoginDialog.vue?vue&type=style&index=0&id=1b79314e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b79314e",
  null
  
)

export default component.exports