<template>
  <div class="mt-3">

    <template
    >
      <v-btn
          class=""
          text
          x-small
          @click="goLink('message')"
      >
        <v-badge
            bordered
            color="red"
            overlap
        >
          <template v-slot:badge>
            <span>{{ unreadMessage }}</span>
          </template>
          <v-icon>mdi-email-plus-outline</v-icon>
        </v-badge>
      </v-btn>

<!--      <button
          class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root  css-em93ji"
          tabindex="0" type="button" @click="$move('/partner/dashboard')" >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 124 130" xml:space="preserve"><g><g><path fill="#FFFFFF" d="M57.23,67.2l-2.54,14.43c-0.24,1.36-0.23,2.41,0.04,3.15c0.27,0.74,0.72,1.26,1.34,1.57 c0.63,0.31,1.55,0.51,2.79,0.6c1.23,0.09,2.38,0.16,3.44,0.2l-0.33,1.87H37.22l0.33-1.87c1.85-0.05,3.24-0.15,4.19-0.31 c0.94-0.16,1.73-0.47,2.34-0.92c0.61-0.46,1.08-1.04,1.4-1.76c0.32-0.71,0.59-1.7,0.81-2.94l5.29-29.98 c0.44-2.5,0.65-4.27,0.61-5.31c-0.03-1.05-0.54-1.82-1.53-2.31c-0.99-0.5-2.76-0.75-5.3-0.75l0.33-1.87H69.1 c1.05,0,3.51,0.18,7.39,0.54c1.25,0.11,2.54,0.49,3.87,1.12c1.33,0.63,2.53,1.5,3.59,2.6c1.07,1.1,1.85,2.41,2.36,3.91 c0.51,1.51,0.6,3.15,0.29,4.92c-0.35,1.97-1.06,3.75-2.11,5.34c-1.07,1.58-2.51,2.96-4.33,4.12c-1.83,1.16-4.03,2.05-6.6,2.69 c-2.58,0.63-5.5,0.95-8.76,0.95H57.23z M57.56,65.32h4.76c2.88,0,5.34-0.35,7.36-1.04c2.03-0.69,3.7-1.86,5.01-3.51 c1.31-1.64,2.21-3.85,2.7-6.62c0.73-4.15-0.11-7.26-2.54-9.33c-0.97-0.82-2-1.35-3.09-1.6c-1.09-0.25-2.48-0.37-4.15-0.37 c-1.93,0-3.37,0.27-4.31,0.82c-0.95,0.54-1.63,1.36-2.04,2.45c-0.41,1.09-0.8,2.7-1.17,4.83L57.56,65.32z"></path></g></g></svg>
        <span class="MuiTouchRipple-root css-w0pj6f"></span></button>-->

      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          allow-overflow
          bottom
          right
          offset-y
          min-width="30%"
          origin="top right"
          transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
              class="ml-2"
              text
              x-small
              v-bind="attrs"
              v-on="on"
              @click="openStatusBoard"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-card
            class="text-right mb-0 "
            width="100%"
            ref="statusBoard"
        >
          <v-card-text>
            <div class="text-right">
              <div
                  color="blue-grey lighten-5"
              >
                <div class="mb-2 align-center">
                  <div class="align-center">
                    <div @click="$move('/partner/dashboard')">
                      <span style="color:red">파트너관리</span>
                    </div>

                  </div>
                </div>
                <v-divider
                    class=" secondary"
                ></v-divider>

                <div class="mb-2" @click="logout">
                  로그아웃
                </div>
              </div>

              <v-divider
                  class=" secondary"
              ></v-divider>

            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </div>
</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'
// Vuex

export default {
  name: 'DashboardCoreSettings',
  data: () => ({
    menu: false,
    calcCartHeight: 0
  }),
  props: {

    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    },
    isLogin: {
      type: Boolean
    },
    role: {
      type: String
    },
    hasChildren: {
      type: String
    }
  },

  methods: {
    logout: function () {

      this.$emit('logout');
    },
    openStatusBoard: async function () {
      await this.$nextTick();
      console.log('window', window.innerHeight)
      setTimeout(() => {
        console.log(this.$refs.statusBoard)
        console.log(this.$refs.statusBoard.$el.clientHeight)
        console.log(this.$refs.statusBoard.$el.scrollHeight)
        console.log(window.innerHeight)
      }, 400)

    },
    openRecommenderDialog: function () {
      this.$emit('openRecommenderDialog')
    },
    openLoginDialog: function () {
      this.$emit('openLoginDialog')
    },
    goLink: function (target) {
      this.$emit('goLink', target);
    },

  },
  mounted() {
  },
  computed: {},
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
