<template>
  <v-dialog
      :value="dialog"
      @input="toggleDialog"
      max-width="500px"
  >
  <div class="contentArea site_bg" style="background-color: #262626 !important;">
    <!-- 내용 시작 -->
    <!--loginBoxTrans-->
    <div class="loginBoxTrans">
      <h2><img src="@/assets/mobile/img/timglogin.png"></h2>
      <span class="ipt_wr gy">
					<input type="text" v-model="userId" name="userid" title="아이디" placeholder="아이디" id="userid">
				</span>
      <span class="ipt_wr gy">
					<input type="password" v-model="userPassword" title="비밀번호" placeholder="비밀번호를 입력해 주세요" name="password" id="password"
                 autocomplete="off/">
      </span>
      <!--btn_area-->
      <div class="btn_area mt15">
        <a @click="login(userId,userPassword)" class="bt_line_wt wm130">로그인</a>
      </div>

      <!--//btn_area-->
    </div>
    <!--//loginBoxTrans-->
    <!-- //내용 끝 -->

  </div>
  </v-dialog>

</template>


<script>



// import axios from "axios";
import {eventBus} from "@/plugins/eventBus";

export default {
  name: "MemberLogin",
  data: () => ({
    loading: false,
    userId: '',
    userPassword: '',
    passwordShow: false,
  }),
  components: {

  },
  props:{
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    }
  },

  methods: {

    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:dialog', !this.dialog);
      // this.$emit('input');
    },

    login: function (id,pw) {
      this.$emit('login',id,pw);
    },


  }


}
</script>

<style scoped>
.mainLogo {
  padding: 80px 0;
}

</style>
