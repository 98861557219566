<template>
  <v-dialog
      :value="dialog"
      @input="recommenderAlertAction"
      max-width="500"
  >
    <validation-observer
        ref="boardWriteForm"
        v-slot="{ invalid }"
    >

      <form
          class=""
          @submit.prevent="checkRecommender"
      >
        <v-card>
          <v-card-title>
            추천인을 입력해 주세요
          </v-card-title>
          <v-card-text class="pa-5 text-center">
            <validation-provider
                v-slot="{ errors }"
                name="recommender"
                rules="required"
            >
              <v-text-field
                  v-model="recommender"
                  solo
                  hide-details
                  required
                  name="recommender"
                  class="rounded-0 searchInput"
                  placeholder="추천인"
                  background-color="#090909"
              ></v-text-field>
            </validation-provider>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                tile
                color="primary"

                height="48"
                type="submit"
                :disabled="invalid"
            >
              작성 완료
            </v-btn>
            <v-btn
                color="error"
                tile
                height="48"
                @click="recommenderAlertAction"
            >
              취소
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
export default {
  name: "TextAlert",
  data: () => ({
    recommender: '',
  }),
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    text: {
      type: String,
    },

  },
  methods: {
    recommenderAlertAction: function () {
      this.$emit('recommenderAlertAction');
    },
    checkRecommender: function () {
      this.$emit('checkRecommender',this.recommender)
    }
  }
}
</script>

<style scoped>

</style>