import Vue from "vue"

Vue.filter('mmddhhmmss', (value) => {
    if (value === '') return '';

    // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
    let fullDate = new Date(value);

    // 연도, 월, 일 추출
    let month = fullDate.getMonth() + 1 < 10 ? "0" + (fullDate.getMonth() + 1) : fullDate.getMonth() + 1;
    let date = fullDate.getDate() < 10 ? "0" + fullDate.getDate() : fullDate.getDate();
    let hh = fullDate.getHours() < 10 ? "0" + fullDate.getHours() : fullDate.getHours();
    let mm = fullDate.getMinutes() < 10 ? "0" + fullDate.getMinutes() : fullDate.getMinutes();
    let ss = fullDate.getSeconds() < 10 ? "0" + fullDate.getSeconds() : fullDate.getSeconds();
    // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리

    // 최종 포맷 (ex - '2021-10-08')
    return month + '-' + date + ' ' + hh + ':' + mm + ':' + ss;
})
Vue.filter("makeComma", val =>{
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})
Vue.filter('mmddhhmm', (value) => {
    if (value === '') return '';

    // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
    let fullDate = new Date(value);

    // 연도, 월, 일 추출
    let month = fullDate.getMonth() + 1 < 10 ? "0" + (fullDate.getMonth() + 1) : fullDate.getMonth() + 1;
    let date = fullDate.getDate() < 10 ? "0" + fullDate.getDate() : fullDate.getDate();
    let hh = fullDate.getHours() < 10 ? "0" + fullDate.getHours() : fullDate.getHours();
    let mm = fullDate.getMinutes() < 10 ? "0" + fullDate.getMinutes() : fullDate.getMinutes();
    // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리

    // 최종 포맷 (ex - '2021-10-08')
    return month + '-' + date + ' ' + hh + ':' + mm;
})

Vue.filter('hhmm', (value) => {
    if (value === '') return '';

    // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
    let fullDate = new Date(value);

    // 연도, 월, 일 추출
    let hh = fullDate.getHours() < 10 ? "0" + fullDate.getHours() : fullDate.getHours();
    let mm = fullDate.getMinutes() < 10 ? "0" + fullDate.getMinutes() : fullDate.getMinutes();
    // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리

    // 최종 포맷 (ex - '2021-10-08')
    return hh + ':' + mm;
})

Vue.filter("comma", val => {
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

Vue.filter("betDate", val => {
    if (val.includes('(0)')) {
        val = val.replace('(0)', '(일)')
    } else if (val.includes('(1)')) {
        val = val.replace('(1)', '(월)')
    } else if (val.includes('(2)')) {
        val = val.replace('(2)', '(화)')
    } else if (val.includes('(3)')) {
        val = val.replace('(3)', '(수)')
    } else if (val.includes('(4)')) {
        val = val.replace('(4)', '(목)')
    } else if (val.includes('(5)')) {
        val = val.replace('(5)', '(금)')
    } else if (val.includes('(6)')) {
        val = val.replace('(6)', '(토)')
    }
    // 'MM/DD HH:mm'
    return val
})